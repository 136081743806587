import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ControlContainer, FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MessagingService } from '@bb-core/service';
import { TranslateModule } from '@ngx-translate/core';
import { NEVER, Observable, catchError, combineLatest, filter, map, of, startWith, switchMap, take } from 'rxjs';
import { formViewProvider } from '../../../../../shared/provider/form-view.provider';
import { BbCheckboxComponent } from '../../../../../shared/ui/form/bb-checkbox/bb-checkbox.component';
import { BbInputComponent } from '../../../../../shared/ui/form/bb-input/bb-input.component';
import { BbSelectComponent } from '../../../../../shared/ui/form/bb-select/bb-select.component';
import { ShopifyRepository } from '../shopify.repository';

@Component({
    selector: 'bb-shopify-general-form',
    templateUrl: './shopify-general-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [formViewProvider],
    standalone: true,
    imports: [FormsModule, TranslateModule, BbInputComponent, MatTooltipModule, BbCheckboxComponent, BbSelectComponent],
})
export class ShopifyGeneralFormComponent {
    private readonly controlContainer = inject(ControlContainer);
    private readonly shopifyRepository = inject(ShopifyRepository);
    private readonly messagingService = inject(MessagingService);

    protected readonly stockLocationOptions = toSignal(
        this.shopifyFieldsLoaded$().pipe(
            switchMap(() =>
                combineLatest([
                    this.controlContainer.control?.get('ShopName')?.valueChanges ?? NEVER,
                    this.controlContainer.control?.get('ShopifyAccessToken')?.valueChanges ?? NEVER,
                ]).pipe(
                    startWith([
                        this.controlContainer.control?.getRawValue().ShopName,
                        this.controlContainer.control?.getRawValue().ShopifyAccessToken,
                    ]),
                    switchMap(([ShopName, ShopifyAccessToken]) =>
                        this.shopifyRepository.getStockLocations(ShopifyAccessToken, ShopName).pipe(
                            catchError(() => {
                                this.messagingService.showSnackBar('shop_form.cant_fetch_shop_locations');
                                return of([]);
                            }),
                        ),
                    ),
                    map(items => [
                        { Value: null, Key: '-' },
                        ...items.map(({ Id, name }) => ({ Value: Id, Key: name })),
                    ]),
                ),
            ),
        ) ?? of([]),
    );

    private shopifyFieldsLoaded$(): Observable<void> {
        return (
            this.controlContainer.control?.valueChanges.pipe(
                map(() => this.controlContainer.control?.getRawValue()),
                startWith(this.controlContainer.control?.getRawValue()),
                filter(({ ShopName, ShopifyAccessToken }) => !!ShopName && !!ShopifyAccessToken),
                take(1),
            ) ?? NEVER
        );
    }
}
