<bb-input
    [label]="'label.discount_code_title' | translate"
    [matTooltip]="'tooltip.discount_code_title' | translate"
    [subscriptSizing]="'dynamic'"
    ngModel
    name="ShopifyDiscountCodeTitle"
></bb-input>

<bb-checkbox
    [label]="'label.create_cancel_orders' | translate"
    [matTooltip]="'tooltip.create_cancel_orders' | translate"
    ngModel
    name="CreateCancelOrders"
></bb-checkbox>
<bb-checkbox
    [label]="'label.use_title_instead_of_name' | translate"
    [matTooltip]="'tooltip.use_title_instead_of_name' | translate"
    ngModel
    name="UseTitleInsteadOfName"
></bb-checkbox>
<bb-checkbox
    [label]="'label.import_customer_for_pos_sales' | translate"
    [matTooltip]="'tooltip.import_customer_for_pos_sales' | translate"
    ngModel
    name="ImportCustomerForPosSales"
></bb-checkbox>
<bb-checkbox
    [label]="'label.handle_authorized_as_pending' | translate"
    [matTooltip]="'tooltip.handle_authorized_as_pending' | translate"
    ngModel
    name="HandleAuthorizedAsPending"
></bb-checkbox>
<bb-checkbox
    [label]="'label.handle_closed_at_as_completed' | translate"
    [matTooltip]="'tooltip.handle_closed_at_as_completed' | translate"
    ngModel
    name="HandleClosedAtAsCompleted"
></bb-checkbox>
<bb-checkbox
    [label]="'label.import_customer_tags_as_order_tags' | translate"
    [matTooltip]="'tooltip.import_customer_tags_as_order_tags' | translate"
    ngModel
    name="ImportCustomerTagsAsOrderTags"
></bb-checkbox>
<bb-checkbox
    [label]="'label.use_presentment_currency' | translate"
    [matTooltip]="'tooltip.use_presentment_currency' | translate"
    ngModel
    name="UsePresentmentCurrency"
></bb-checkbox>
<bb-checkbox
    [label]="'label.deactivate_web_hooks' | translate"
    [matTooltip]="'tooltip.deactivate_web_hooks' | translate"
    ngModel
    name="DeactivateWebHooks"
></bb-checkbox>

<bb-select
    [label]="('general.storage_location' | translate) + ' Shopify'"
    [options]="stockLocationOptions()"
    ngModel
    name="ShopifyLocationId"
    matTooltip="Legt die Shopify Location fest, die für Fulfillment und Bestandsabgleich verwendet wird."
>
</bb-select>
